import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminComponent from "./../../Componet/Layout/AdminComponent";
import "../../Styles/Task.css"; // Create a separate CSS file for styling

function TaskManagement() {
  const [taskType, setTaskType] = useState("");
  const [accountType, setAccountType] = useState("");
  const [referralRequirement, setReferralRequirement] = useState("");
  const [offerDay, setOfferDay] = useState("");
  const [Links, setLinks] = useState("");
  const [price, setPrice] = useState("");
  const [reward, setReward] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/task/get-task"
      );
      setTasks(response.data.tasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/task/create-task",
        {
          taskType,
          accountType: taskType ? accountType : undefined,
          referralRequirement:
            taskType === "referral" ? referralRequirement : undefined,
          offerDay: taskType === "sundayOffer" ? offerDay : undefined,
          Links: taskType === "link" ? Links : undefined,
          price,
          reward,
        }
      );
      setSuccess(response.data.message);
      setError("");
      fetchTasks(); // Refresh the task list
    } catch (error) {
      setError(error.response.data.error || "Internal server error");
      setSuccess("");
    }
  };

  const handleDelete = async (taskId) => {
    try {
      const response = await axios.delete(
        `https://earning-site-fll-backend-code.onrender.com/api/v1/task/delete-task/${taskId}`
      );
      setSuccess(response.data.message);
      setError("");
      fetchTasks(); // Refresh the task list
    } catch (error) {
      setError(error.response.data.error || "Internal server error");
      setSuccess("");
    }
  };

  return (
    <div className="task-management-container">
      <AdminComponent />
      <h1 className="header">Task Management</h1>

      <form onSubmit={handleSubmit} className="task-form">
        <div className="form-group">
          <label htmlFor="taskType">Task Type:</label>
          <select
            id="taskType"
            value={taskType}
            onChange={(e) => setTaskType(e.target.value)}
            required
          >
            <option value="">Select Task Type</option>
            <option value="referral">Referral</option>
            <option value="sundayOffer">Sunday Offer</option>
            <option value="link">Link</option>
          </select>
        </div>

        {taskType && (
          <>
            <div className="form-group">
              <label htmlFor="accountType">Account Type:</label>
              <input
                id="accountType"
                type="text"
                value={accountType}
                onChange={(e) => setAccountType(e.target.value)}
              />
            </div>

            {taskType === "referral" && (
              <div className="form-group">
                <label htmlFor="referralRequirement">
                  Referral Requirement:
                </label>
                <input
                  id="referralRequirement"
                  type="number"
                  value={referralRequirement}
                  onChange={(e) => setReferralRequirement(e.target.value)}
                  required
                  min="1"
                />
              </div>
            )}

            {taskType === "sundayOffer" && (
              <div className="form-group">
                <label htmlFor="offerDay">Offer Day:</label>
                <input
                  id="offerDay"
                  type="text"
                  value={offerDay}
                  onChange={(e) => setOfferDay(e.target.value)}
                  required
                />
              </div>
            )}

            {taskType === "link" && (
              <div className="form-group">
                <label htmlFor="Links">Link:</label>
                <input
                  id="Links"
                  type="url"
                  value={Links}
                  onChange={(e) => setLinks(e.target.value)}
                  required
                />
              </div>
            )}

            <div className="form-group">
              <label htmlFor="price">Price:</label>
              <input
                id="price"
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="reward">Reward:</label>
              <textarea
                id="reward"
                value={reward}
                onChange={(e) => setReward(e.target.value)}
                required
              />
            </div>
          </>
        )}

        <button type="submit" className="submit-button">
          Create Task
        </button>

        {error && <div className="alert alert-error">{error}</div>}
        {success && <div className="alert alert-success">{success}</div>}
      </form>

      <div className="tasks-container">
        <h2 className="subheader">Existing Tasks</h2>
        <table className="tasks-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Details</th>
              <th>Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task) => (
              <tr key={task._id}>
                <td>{task._id}</td>
                <td>{task.taskType}</td>
                <td>
                  {task.accountType && (
                    <div>Account Type: {task.accountType}</div>
                  )}
                  {task.referralRequirement && (
                    <div>Referral Requirement: {task.referralRequirement}</div>
                  )}
                  {task.offerDay && <div>Offer Day: {task.offerDay}</div>}
                  {task.Links && <div>Link: {task.Links}</div>}
                  <div>Reward: {task.reward}</div>
                </td>
                <td>{task.price}</td>
                <td>
                  <button
                    onClick={() => handleDelete(task._id)}
                    className="delete-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TaskManagement;
